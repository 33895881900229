<template lang="pug">
  .fb
    .fb__container.container

      //- Назад
      button.fb__btn-back.btn.btn--back(@click="$router.push({ name: 'Search' })") 
        svg(width="24" height="24")
          use(xlink:href="@/assets/images/sprite/sprite.svg#icon-arrow-back")
        span Назад

      //- Титульная линия страницы
      .fb__top-line
        .fb__title-container.fb__title-container--row
          h1.fb__title {{ title }}
          el-tooltip(
            content="Дополнительная информация"
            placement="right"
            effect="light"
          )
            button.fb__tooltip.btn.btn--tooltip
              svg(width="16" height="16")
                use(xlink:href="@/assets/images/sprite/sprite.svg#icon-info")
      .fb__body

        //- Инпуты расширенного поиска
        form(@submit.prevent).search.search--advanced
          .search__inputs
            .form-el.custom-el
              .form-el__title Муниципальный район/округ
              .form-el__body
                el-autocomplete(
                  v-model="district"
                  :fetch-suggestions="querySearchDistrict"
                  value-key="short_name"
                  placeholder="Выберите район"
                  @select="handleSelectDistrict"
                  :disabled="districtDisabled"
                  class="custom-el"
                )
            .form-el.custom-el
              .form-el__title Поселение
              .form-el__body
                el-autocomplete(
                  v-model="settlement"
                  :fetch-suggestions="querySearchSettlement"
                  value-key="short_name"
                  placeholder="Выберите поселение"
                  @select="handleSelectSettlement"
                  :disabled="settlementDisabled"
                  class="custom-el"
                  popper-class="custom-autocomplete"
                )
            .form-el.custom-el
              .form-el__title Город
              .form-el__body
                el-autocomplete(
                  v-model="city"
                  :fetch-suggestions="querySearchCity"
                  value-key="short_name"
                  placeholder="Выберите город"
                  @select="handleSelectCity"
                  :disabled="cityDisabled"
                  class="custom-el"
                )
            .form-el.custom-el
              .form-el__title Населенный пункт
              .form-el__body
                el-autocomplete(
                  v-model="locality"
                  :fetch-suggestions="querySearchLocality"
                  value-key="short_name"
                  placeholder="Выберите населенный пункт"
                  @select="handleSelectLocality"
                  :disabled="localityDisabled"
                  class="custom-el"
                )
            .form-el.custom-el
              .form-el__title Элемент планировочной структуры
              .form-el__body
                el-autocomplete(
                  v-model="planningStructure"
                  :fetch-suggestions="querySearchPlanningStructure"
                  value-key="short_name"
                  placeholder="Выберите элемент планировочной структуры"
                  @select="handleSelectPlanningStructure"
                  :disabled="planningStructureDisabled"
                  class="custom-el"
                )
            .form-el.custom-el
              .form-el__title Элемент улично-дорожной сети
              .form-el__body
                el-autocomplete(
                  v-model="roadElement"
                  :fetch-suggestions="querySearchRoadElement"
                  value-key="short_name"
                  placeholder="Выберите элемент улично-дорожной сети"
                  @select="handleSelectRoadElement"
                  :disabled="roadElementDisabled"
                  class="custom-el"
                )
            .form-el.custom-el
              .form-el__title Номер здания/сооружения
              .form-el__body
                el-autocomplete(
                  v-model="buildingNumber"
                  :fetch-suggestions="querySearchBuildingNumber"
                  value-key="short_name"
                  placeholder="Выберите номер здания/сооружения"
                  @select="handleSelectBuildingNumber"
                  :disabled="buildingNumberDisabled"
                  class="custom-el"
                )
            .form-el.custom-el
              .form-el__title Номер помещения
              .form-el__body
                el-autocomplete(
                  v-model="premisesNumber"
                  :fetch-suggestions="querySearchPremisesNumber"
                  value-key="short_name"
                  placeholder="Выберите номер помещения"
                  @select="handleSelectPremisesNumber"
                  :disabled="premisesNumberDisabled"
                  class="custom-el"
                )
            .form-el.custom-el
              .form-el__title Номер комнаты в пределах помещения
              .form-el__body
                el-autocomplete(
                  v-model="roomNumber"
                  :fetch-suggestions="querySearchRoomNumber"
                  value-key="short_name"
                  placeholder="Выберите комнату"
                  @select="handleSelectRoomNumber"
                  :disabled="roomNumberDisabled"
                  class="custom-el"
                )
            .form-el.custom-el
              .form-el__title Почтовый индекс
              .form-el__body
                el-input(
                  v-model="postalCode"
                  placeholder="Введите почтовый индекс"
                  :disabled="postalCodeDisabled"
                )
            .form-el.custom-el
              .form-el__title Уникальный номер адреса объекта в государственном адресном реестре
              .form-el__body
                el-input(
                  v-model="uniqueNumber"
                  placeholder="Введите уникальный номер адреса объекта в государственном адресном реестре"
                  :disabled="uniqueNumberDisabled"
                )
            .form-el.custom-el
              .form-el__title Кадастровый номер
              .form-el__body
                el-input(
                  v-model="cadastralNumber"
                  placeholder="Введите кадастровый номер"
                  :disabled="cadastralNumberDisabled"
                )
          .search__btn-group
            button(@click="handleReset()" type="button").search__btn.search__btn--auto.btn.btn--transparent Очистить
            button(@click.prevent="getObject()").search__btn.btn.btn--main Найти

        .fb__content(
          :class="{ 'fb__content--min-height': showPreload }"
        )
          a-preload(
            v-show="showPreload"
          )
          //- Отображение найденного объекта
          Object(
            v-if="object"
            :object="object"
            @changeParams="changeParams"
          )
</template>

<script>
import Object from '../components/Object'
import AFormEl from '../../../../components/a-form-el/a-form-el'
import APreload from '../../../../components/a-preload/a-preload'

export default {
  name: 'AdvancedSearch',

  components: {
    Object,
    AFormEl,
    APreload
  },

  data() {
    return {
      title: 'Поиск объекта',
      showPreload: false,

      // инпуты расширенного поиска
      district: '',
      districtID: '',
      districtDisabled: false,
      settlement: '',
      settlementID: '',
      settlementDisabled: true,
      city: '',
      cityID: '',
      cityDisabled: true,
      locality: '',
      localityID: '',
      localityDisabled: true,
      planningStructure: '',
      planningStructureID: '',
      planningStructureDisabled: true,
      roadElement: '',
      roadElementID: '',
      roadElementDisabled: true,
      buildingNumber: '',
      buildingNumberID: '',
      buildingNumberDisabled: true,
      premisesNumber: '',
      premisesNumberID: '',
      premisesNumberDisabled: true,
      roomNumber: '',
      roomNumberID: '',
      roomNumberDisabled: true,
      postalCode: '',
      postalCodeDisabled: false,
      uniqueNumber: '',
      uniqueNumberDisabled: false,
      cadastralNumber: '',
      cadastralNumberDisabled: false,
    }
  },

  computed: {
    object() {
      return this.$store.getters["advancedSearch/object"];
    },
  },

  methods: {
    querySearchDistrict(queryString, cb) {
      this.$store.dispatch("advancedSearch/getDistrictList", { name: this.district }, queryString).then((data) => {
        (data.length === 0) ? cb([]) : cb(data);
      })
    },

    querySearchSettlement(queryString, cb) {
      this.$store.dispatch("advancedSearch/getSettlementList", { name: this.settlement, mun_district: this.districtID}, queryString).then((data) => {
        (data.length === 0) ? cb([]) : cb(data);
      })
    },

    querySearchCity(queryString, cb) {
      this.$store.dispatch("advancedSearch/getCityList", { name: this.city, settlement: this.settlementID }, queryString).then((data) => {
        if (data.length !== 0) {
          cb(data)
        } else {
          this.$store.dispatch("advancedSearch/getCityList", { name: this.city, mun_district: this.districtID }, queryString).then((data) => {
            (data.length === 0) ? cb([]) : cb(data); 
          })
        }
      })
      if (this.district.indexOf("городской округ город") != -1) {
        this.$store.dispatch("advancedSearch/getCityList", { name: this.city, mun_district: this.districtID }, queryString).then((data) => {
          (data.length === 0) ? cb([]) : cb(data); 
        })
      }
    },

    querySearchLocality(queryString, cb) {
      this.$store.dispatch("advancedSearch/getLocalityList", { name: this.locality, mun_district: this.districtID, settlement: this.settlementID }, queryString).then((data) => {
        if (data.length !== 0) {
          cb(data);
        } else {
          this.$store.dispatch("advancedSearch/getLocalityList", { name: this.locality, settlement: this.settlementID }, queryString).then((data) => {
            (data.length === 0) ? cb([]) : cb(data); 
          })
        }
      })
    },

    querySearchPlanningStructure(queryString, cb) {
      if (this.district.indexOf("городской округ город") != -1) {
        this.$store.dispatch("advancedSearch/getPlanningStructureList", { name: this.planningStructure, city: this.cityID, locality: this.localityID, settlement: this.districtID }, queryString).then((data) => {
          (data.length === 0) ? cb([]) : cb(data);
        })
      }
      this.$store.dispatch("advancedSearch/getPlanningStructureList", { name: this.planningStructure, city: this.cityID, locality: this.localityID, settlement: this.settlementID }, queryString).then((data) => {
        (data.length === 0) ? cb([]) : cb(data);
      })
    },

    querySearchRoadElement(queryString, cb) {
      this.$store.dispatch("advancedSearch/getRoadElementList", { name: this.roadElement, plan_structure: this.planningStructureID, settlement: this.cityID }, queryString).then((data) => {
        if (data.length !== 0) {
          cb(data);
        } else {
          this.$store.dispatch("advancedSearch/getRoadElementList", { name: this.roadElement, plan_structure: this.planningStructureID, settlement: this.localityID }, queryString).then((data) => {
            (data.length === 0) ? cb([]) : cb(data); 
          })
        }
      })
    },

    querySearchBuildingNumber(queryString, cb) {
      this.$store.dispatch("advancedSearch/getBuildingNumberList", { name: this.buildingNumber, street: this.roadElementID, locality: this.localityID }, queryString).then((data) => {
        (data.length === 0) ? cb([]) : cb(data);
      })
    },

    querySearchPremisesNumber(queryString, cb) {
      this.$store.dispatch("advancedSearch/getPremisesNumberList", { name: this.premisesNumber, house: this.buildingNumberID }, queryString).then((data) => {
        (data.length === 0) ? cb([]) : cb(data);
      })
    },

    querySearchRoomNumber(queryString, cb) {
      this.$store.dispatch("advancedSearch/getRoomNumberList", { name: this.roomNumber, apart: this.premisesNumberID }, queryString).then((data) => {
        (data.length === 0) ? cb([]) : cb(data);
      })
    },
    
    handleSelectDistrict(item) {
      if (item.objectid) {
        this.districtID = item.objectid
        this.settlement = ''
        this.settlementID = ''
        this.settlementDisabled = false
        this.city = ''
        this.cityID = ''
        this.cityDisabled = true
        this.locality = ''
        this.localityID = ''
        this.localityDisabled = true
        this.planningStructure = ''
        this.planningStructureID = ''
        this.planningStructureDisabled = true
        this.roadElement = ''
        this.roadElementID = ''
        this.roadElementDisabled = true
        this.buildingNumber = ''
        this.buildingNumberID = ''
        this.buildingNumberDisabled = true
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.premisesNumberDisabled = true
        this.roomNumber = ''
        this.roomNumberID = ''
        this.roomNumberDisabled = true
      }
      if (item.short_name.indexOf("городской округ город") != -1) {
        this.$store.dispatch("advancedSearch/getCityList", { name: this.city, mun_district: this.districtID }).then((data) => {
          (data.length === 0) ? this.cityDisabled = true : this.cityDisabled = false
        })
        this.$store.dispatch("advancedSearch/getLocalityList", { name: this.locality, mun_district: this.districtID }).then((data) => {
          (data.length === 0) ? this.localityDisabled = true : this.localityDisabled = false
        })
        this.$store.dispatch("advancedSearch/getPlanningStructureList", { name: this.locality, city: this.districtID }).then((data) => {
          (data.length === 0) ? this.planningStructureDisabled = true : this.planningStructureDisabled = false
        })
        this.settlementDisabled = true
      }
    },

    handleSelectSettlement(item) {
      console.log(item)
      if (item.objectid) {
        this.settlementID = item.objectid
        this.$store.dispatch("advancedSearch/getCityList", { name: this.city, mun_district: this.districtID }).then((data) => {
          if (data.length !== 0) {
            this.cityDisabled = false
          } else {
            this.$store.dispatch("advancedSearch/getLocalityList", { name: this.locality, settlement: this.settlementID }).then((data) => {
              (data.length === 0) ? this.localityDisabled = true : this.localityDisabled = false
            })
          }
        })
        this.city = ''
        this.cityID = ''
        this.cityDisabled = true
        this.locality = ''
        this.localityID = ''
        this.localityDisabled = false
        this.planningStructure = ''
        this.planningStructureID = ''
        this.planningStructureDisabled = true
        this.roadElement = ''
        this.roadElementID = ''
        this.roadElementDisabled = true
        this.buildingNumber = ''
        this.buildingNumberID = ''
        this.buildingNumberDisabled = true
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.premisesNumberDisabled = true
        this.roomNumber = ''
        this.roomNumberID = ''
        this.roomNumberDisabled = true
      }
      if (item.short_name.indexOf("городское поселение город") != -1) {
        this.cityDisabled = false
      }
      if (item.short_name.indexOf("сельское поселение село") != -1) {
        this.planningStructureDisabled = false
      }
    },

    handleSelectCity(item) {
      if (item.objectid) {
        this.cityID = item.objectid
        this.$store.dispatch("advancedSearch/getPlanningStructureList", { name: this.planningStructure, city: this.cityID, locality: this.localityID, settlement: this.settlementID }).then((data) => {
          if (data.length !== 0) {
            this.planningStructureDisabled = false
          } 
          this.$store.dispatch("advancedSearch/getRoadElementList", { name: this.roadElement, plan_structure: this.planningStructureID, settlement: this.cityID }).then((data) => {
            if (data.length !== 0) {
              this.roadElementDisabled = false
            } else {
              this.$store.dispatch("advancedSearch/getBuildingNumberList", { name: this.buildingNumber, street: this.roadElementID, locality: this.localityID }).then((data) => {
                (data.length === 0) ? this.buildingNumberDisabled = true : this.buildingNumberDisabled = false
              })
            }
          })
        })
        this.localityDisabled = true
        this.planningStructure = ''
        this.planningStructureID = ''
        this.roadElement = ''
        this.roadElementID = ''
        this.buildingNumber = ''
        this.buildingNumberID = ''
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.premisesNumberDisabled = true
        this.roomNumber = ''
        this.roomNumberID = ''
        this.roomNumberDisabled = true
      }
    },

    handleSelectLocality(item) {
      if (item.objectid) {
        this.localityID = item.objectid
        this.$store.dispatch("advancedSearch/getPlanningStructureList", { name: this.planningStructure, city: this.cityID, locality: this.localityID, settlement: this.settlementID }).then((data) => {
          if (data.length !== 0) {
            this.planningStructureDisabled = false
          } 
          this.$store.dispatch("advancedSearch/getRoadElementList", { name: this.roadElement, plan_structure: this.planningStructureID, settlement: this.localityID }).then((data) => {
            if (data.length !== 0) {
              this.roadElementDisabled = false
            } else {
              this.$store.dispatch("advancedSearch/getBuildingNumberList", { name: this.buildingNumber, street: this.roadElementID, locality: this.localityID }).then((data) => {
                (data.length === 0) ? this.buildingNumberDisabled = true : this.buildingNumberDisabled = false
              })
            }
          })
        })
        this.cityDisabled = true
        this.planningStructure = ''
        this.planningStructureID = ''
        this.roadElement = ''
        this.roadElementID = ''
        this.buildingNumber = ''
        this.buildingNumberID = ''
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.premisesNumberDisabled = true
        this.roomNumber = ''
        this.roomNumberID = ''
        this.roomNumberDisabled = true
      }
    },

    handleSelectPlanningStructure(item) {
      if (item.objectid) {
        this.planningStructureID = item.objectid
        this.$store.dispatch("advancedSearch/getRoadElementList", { name: this.roadElement, plan_structure: this.planningStructureID, settlement: this.localityID }).then((data) => {
          if (data.length !== 0) {
            this.roadElementDisabled = false
          } else {
            this.$store.dispatch("advancedSearch/getBuildingNumberList", { name: this.buildingNumber, street: this.roadElementID, locality: this.localityID }).then((data) => {
              (data.length === 0) ? this.buildingNumberDisabled = true : this.buildingNumberDisabled = false
            })
          }
        })
        if (!this.city && !this.locality) {
          this.cityDisabled = true
          this.localityDisabled = true
        }
        this.roadElement = ''
        this.roadElementID = ''
        this.buildingNumber = ''
        this.buildingNumberID = ''
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.premisesNumberDisabled = true
        this.roomNumber = ''
        this.roomNumberID = ''
        this.roomNumberDisabled = true
      }
    },

    handleSelectRoadElement(item) {
      if (item.objectid) {
        this.roadElementID = item.objectid
        this.$store.dispatch("advancedSearch/getRoadElementList", {street: this.roadElementID})
        this.buildingNumber = ''
        this.buildingNumberID = ''
        this.buildingNumberDisabled = false
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.premisesNumberDisabled = true
        this.roomNumber = ''
        this.roomNumberID = ''
        this.roomNumberDisabled = true
      }
    },
    
    handleSelectBuildingNumber(item) {
      if (item.objectid) {
        this.buildingNumberID = item.objectid
        this.$store.dispatch("advancedSearch/getPremisesNumberList", { name: this.premisesNumber, house: this.buildingNumberID }).then((data) => {
          if (data.length !== 0) {
            this.premisesNumberDisabled = false
          } else {
            this.$store.dispatch("advancedSearch/getRoomNumberList", { name: this.roomNumber, apart: this.premisesNumberID }).then((data) => {
              (data.length === 0) ? this.roomNumberDisabled = true : this.roomNumberDisabled = false
            })
          }
        })
        this.premisesNumber = ''
        this.premisesNumberID = ''
        this.roomNumber = ''
        this.roomNumberID = ''
      }
    },

    handleSelectPremisesNumber(item) {
      if (item.objectid) {
        this.premisesNumberID = item.objectid
        this.$store.dispatch("advancedSearch/getRoomNumberList", { name: this.roomNumber, apart: this.premisesNumberID }).then((data) => {
          (data.length === 0) ? this.roomNumberDisabled = true : this.roomNumberDisabled = false
        })
        this.roomNumber = ''
        this.roomNumberID = ''
      }
    },

    handleSelectRoomNumber(item) {
      if (item.objectid) {
        this.roomNumberID = item.objectid
      }
    },

    async getObject() {
      if (this.cadastralNumber) {
        this.showPreload = true
        await this.$store.dispatch("advancedSearch/getObjectByCadNum", { cadnum: this.cadastralNumber })
        this.showPreload = false
      } else {
        const data_ = {}
        if (this.districtID) {
          data_['mun_district'] = this.districtID
        }
        if (this.settlementID) {
          data_['settlement'] = this.settlementID
        }
        if (this.cityID) {
          data_['city'] = this.cityID
        }
        if (this.localityID) {
          data_['locality'] = this.localityID
        }
        if (this.planningStructureID) {
          data_['plan_structure'] = this.planningStructureID
        }
        if (this.roadElementID) {
          data_['street'] = this.roadElementID
        }
        if (this.buildingNumberID) {
          data_['house'] = this.buildingNumberID
        }
        if (this.premisesNumberID) {
          data_['apart'] = this.premisesNumberID
        }
        if (this.roomNumberID) {
          data_['stead'] = this.roomNumberID
        }
        this.showPreload = true
        await this.$store.dispatch("advancedSearch/getObject", data_)
        this.showPreload = false
      }
    },

    handleReset() {
      this.district = ''
      this.districtID = ''
      this.districtDisabled = false
      this.settlement = ''
      this.settlementID = ''
      this.settlementDisabled = true
      this.city = ''
      this.cityID = ''
      this.cityDisabled = true
      this.locality = ''
      this.localityID = ''
      this.localityDisabled = true
      this.planningStructure = ''
      this.planningStructureID = ''
      this.planningStructureDisabled = true
      this.roadElement = ''
      this.roadElementID = ''
      this.roadElementDisabled = true
      this.buildingNumber = ''
      this.buildingNumberID = ''
      this.buildingNumberDisabled = true
      this.premisesNumber = ''
      this.premisesNumberID = ''
      this.premisesNumberDisabled = true
      this.roomNumber = ''
      this.roomNumberID = ''
      this.roomNumberDisabled = true
      this.postalCode = ''
      this.postalCodeDisabled = false
      this.uniqueNumber = ''
      this.uniqueNumberDisabled = false
      this.cadastralNumber = ''
      this.cadastralNumberDisabled = false
    },

    async changeParams(postalCode, cadastralNumber) {
      const data_ = {}
      if (postalCode) {
        data_['pochta_index'] = postalCode
      }
      if (cadastralNumber) {
        data_['kadastr'] = cadastralNumber
      }
      if (this.buildingNumberID) {
        data_['objectid'] = this.buildingNumberID
      }
      try {
        this.showPreload = true
        await this.$store.dispatch("changeHouseParams/changeHouseParams", data_)
        await this.getObject()
        this.showPreload = false
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
